import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Datepicker from "../components/Datepicker";
import AddDistributor from "../features/distributors/AddDistributor";
import { preSignedURL as distributorPreSignedURL } from "../features/distributors/distributorsSlice";
import AddProduct from "../features/products/AddProduct";
import { preSignedURL as productPreSignedURL } from "../features/products/productsSlice";
import Program from "../features/programs/Program";
import AddStore from "../features/stores/AddStore";
import AddUser from "../features/users/AddUser";
import { preSignedURL } from "../features/users/usersSlice";
import CSVDownload from "./CSVDownload";
import LocalDialog from "./LocalDialog";
import TableSelect from "./TableSelect";
import AddMemberGroup from "../features/members/AddMemberGroup";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontSize: 12,
    maxWidth: 180,
    padding: 0,
    paddingBottom: 5,
    paddingRight: 6,
    paddingLeft: 6,
  },
  body: {
    cursor: "pointer",
    border: "1px solid rgb(224, 224, 224)",
    fontSize: 12,
    lineHeight: 1.43,
    maxWidth: 180,
    textOverflow: "ellipsis",
    overflow: "hidden",
    padding: 0,
    paddingRight: 6,
    paddingLeft: 6,
  },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function ColumnMenu(props) {
  const { rowList, handleRowFilter } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="column-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          marginLeft: 8,
          color: "#FFF",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="column-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: 250,
            padding: 24,
            paddingLeft: 8,
          },
        }}
      >
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{ paddingLeft: 16, marginBottom: 24 }}
          >
            Show Columns
          </FormLabel>
          <FormGroup>
            {rowList.map((option, index) => (
              <MenuItem
                key={option.id + index}
                style={{ padding: 0, paddingLeft: 16 }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={option.checked}
                      onChange={handleRowFilter}
                      name={option.object ? option.objectProperty : option.id}
                    />
                  }
                  label={option.label}
                />
              </MenuItem>
            ))}
          </FormGroup>
          <FormHelperText
            style={{ paddingLeft: 16, marginTop: 24 }}
          ></FormHelperText>
        </FormControl>
      </Menu>
    </div>
  );
}

function TableActions(props) {
  const {
    classes,
    headCells,
    handleRowFilter,
    searchTerm,
    username,
    userMobile,
    userEmail,
    setSearchTerm,
    setUserEmail,
    setUsername,
    setUserMobile,
    rowList,
    handleSearch,
    handleClear,
    editItem,
    setEdit,
    actionsLists,
    actionsHandleChange,
    actionsNames,
    area,
    selectNames,
    data,
    setDateBefore,
    setDateAfter,
    dateAfter,
    dateBefore,
    productsAmount,
    getFullList,
    restricted,
  } = props;

  const [localOpen, setLocalOpen] = useState(false);
  const [localValue, setLocalValue] = useState("Australia");
  const [fileName, setFileName] = useState("");

  const uploadFileToAWS = (newValue) => {
    if (fileInputRef.current.files.length) {
      let csvFile = fileInputRef.current.files[0];
      let jwtToken = localStorage.getItem("clientToken");
      let data = {
        objectName: csvFile.name,
        objectType: "csv",
        token: jwtToken,
        csvFile,
        contentType: "text/csv",
        localization: newValue === "Australia" ? "en-AU" : "en-NZ",
      };
      if (area === "client-product") {
        dispatch(productPreSignedURL(data));
      } else if (area === "distributor") {
        dispatch(distributorPreSignedURL(data));
      } else {
        dispatch(preSignedURL(data));
      }
    }
  };

  const handleClose = (newValue) => {
    setLocalOpen(false);
    if (newValue) {
      setLocalValue(newValue);
      uploadFileToAWS(newValue);
    }
  };

  const dispatch = useDispatch();
  const fileInputRef = useRef();

  const handleUpload = (e) => {
    if (fileInputRef.current.files.length) {
      let csvFile = fileInputRef.current.files[0];

      setFileName(csvFile.name);

      setLocalOpen(true);
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const handleEnter = (e) => {
    if (e.which == 13) {
      handleSearch();
    }
  };

  return (
    <div className={classes.actionsContainer}>
      <div className={classes.actionsItem} style={{ marginRight: 0 }}>
        <ColumnMenu
          rowList={rowList}
          headCells={headCells}
          handleRowFilter={handleRowFilter}
        />
      </div>
      <div className={classes.addItem} style={{ flexGrow: 0 }}>
        {area === "user" && !restricted ? <AddUser /> : ""}
        {area === "program" ? <Program /> : ""}
        {area === "client-product" ? (
          <AddProduct editItem={editItem} setEdit={setEdit} />
        ) : (
          ""
        )}
        {area === "distributor" ? (
          <AddDistributor editItem={editItem} setEdit={setEdit} />
        ) : (
          ""
        )}
        {area === "membergroup" ? (
          <AddMemberGroup editItem={editItem} setEdit={setEdit} />
        ) : (
          ""
        )}
        {area === "store" ? (
          <AddStore editItem={editItem} setEdit={setEdit} />
        ) : (
          ""
        )}
      </div>
      {((area === "user" && !restricted) ||
        area === "client-product" ||
        area === "distributor") && (
        <div className={classes.addItem} style={{ flexGrow: 0 }}>
          <input
            ref={fileInputRef}
            accept="text/csv"
            className={classes.input}
            id="icon-button-csv"
            type="file"
            onChange={handleUpload}
            style={{ display: "none" }}
          />
          <label htmlFor="icon-button-csv">
            <IconButton
              aria-label="upload csv"
              onClick={fileInputClicked}
              className={classes.iconButtonAction}
              style={{}}
            >
              <CloudUploadIcon />
            </IconButton>
          </label>
          <LocalDialog
            open={localOpen}
            value={localValue}
            handleClose={handleClose}
            fileName={fileName}
            area={area}
          />
        </div>
      )}
      <CSVDownload
        data={data}
        productsAmount={productsAmount}
        getFullList={getFullList}
        rowList={rowList}
        headCells={headCells}
      />
      {area === "user" && (
        <div style={{ display: "flex" }}>
          <div className={classes.actionsItem}>
            <InputBase
              placeholder="Username..."
              inputProps={{ "aria-label": "search" }}
              id="custom-search-input-one"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyPress={(e) => handleEnter(e)}
              className={classes.inputBaseAction}
            />
          </div>
          <div className={classes.actionsItem}>
            <InputBase
              placeholder="Display Name..."
              inputProps={{ "aria-label": "search" }}
              id="custom-search-input-one"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => handleEnter(e)}
              className={classes.inputBaseAction}
            />
          </div>
          <div className={classes.actionsItem}>
            <InputBase
              placeholder="Mobile..."
              inputProps={{ "aria-label": "search" }}
              id="custom-search-input-two"
              className={classes.inputBaseAction}
              value={userMobile}
              onChange={(e) => setUserMobile(e.target.value)}
              onKeyPress={(e) => handleEnter(e)}
            />
          </div>
          <div className={classes.actionsItem}>
            <InputBase
              placeholder="Email..."
              inputProps={{ "aria-label": "search" }}
              id="custom-search-input-three"
              className={classes.inputBaseAction}
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              onKeyPress={(e) => handleEnter(e)}
            />
          </div>
        </div>
      )}
      {area === "claim" && (
        <div style={{ display: "flex" }}>
          <div className={classes.actionsItem}>
            <InputBase
              placeholder="Invoice #..."
              inputProps={{ "aria-label": "search" }}
              id="custom-search-input-one"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => handleEnter(e)}
              className={classes.inputBaseAction}
            />
          </div>
        </div>
      )}
      {(area === "email" ||
        area === "client-product" ||
        area === "distributor" ||
        area === "program") && (
        <div style={{ display: "flex" }}>
          <div className={classes.actionsItem}>
            <InputBase
              placeholder={area === "email" ? "Recipient Name..." : "Name..."}
              inputProps={{ "aria-label": "search" }}
              id="custom-search-input-one"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => handleEnter(e)}
              className={classes.inputBaseAction}
            />
          </div>
        </div>
      )}

      {area === "redeemed" && (
        <div style={{ display: "flex" }}>
          <div style={{ maxWidth: 180, marginBottom: 16, marginRight: 10 }}>
            <Datepicker
              invoiceDate={dateAfter}
              setInvoiceDate={setDateAfter}
              report={true}
              label={"Redeemed After"}
            />
          </div>
          <div style={{ maxWidth: 180, marginBottom: 16, marginRight: 10 }}>
            <Datepicker
              invoiceDate={dateBefore}
              setInvoiceDate={setDateBefore}
              report={true}
              label={"Redeemed Before"}
            />
          </div>
        </div>
      )}

      {actionsLists.map((list, index) => {
        return (
          <div key={index} className={classes.actionsItem}>
            <TableSelect
              key={index}
              update={false}
              classes={classes}
              filterList={actionsLists[index]}
              filterStatus={actionsNames[index]}
              name={selectNames[index]}
              handleFilterChange={actionsHandleChange[index]}
              multiple={
                area === "product" ? true : selectNames[index] === "status"
              }
            />
          </div>
        );
      })}
      {area === "order" && (
        <div style={{ display: "flex" }}>
          <div style={{ maxWidth: 160, marginBottom: 16, marginRight: 10 }}>
            <Datepicker
              after={true}
              before={false}
              invoiceDate={dateAfter}
              setInvoiceDate={setDateAfter}
              report={true}
            />
          </div>
          <div style={{ maxWidth: 160, marginBottom: 16, marginRight: 10 }}>
            <Datepicker
              after={false}
              before={true}
              invoiceDate={dateBefore}
              setInvoiceDate={setDateBefore}
              report={true}
            />
          </div>
        </div>
      )}
      {area === "email" && (
        <div style={{ display: "flex" }}>
          <div style={{ maxWidth: 160, marginBottom: 16, marginRight: 10 }}>
            <Datepicker
              invoiceDate={dateAfter}
              setInvoiceDate={setDateAfter}
              report={true}
              label="Created After"
            />
          </div>
          <div style={{ maxWidth: 160, marginBottom: 16, marginRight: 10 }}>
            <Datepicker
              invoiceDate={dateBefore}
              setInvoiceDate={setDateBefore}
              report={true}
              label="Created Before"
            />
          </div>
        </div>
      )}
      {area !== "expired" && actionsLists.length > 0 && (
        <div style={{ display: "flex" }}>
          <div
            onClick={handleSearch}
            className={classes.actionsItem}
            style={{ marginRight: 0 }}
          >
            <IconButton aria-label="search">
              <SearchIcon className={classes.searchIcon} style={{}} />
            </IconButton>
          </div>

          <div
            onClick={handleClear}
            style={{ marginRight: 0 }}
            className={classes.actionsItem}
          >
            <IconButton aria-label="clear filters">
              <HighlightOffIcon className={classes.highlightIcon} style={{}} />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    selectAll,
    headCells,
    rowList,
    area,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            style={
              selectAll
                ? { visibility: "visible", paddingBottom: 5 }
                : { visibility: "hidden", paddingBottom: 5 }
            }
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </StyledTableCell>
        {headCells.map((headCell, index) => (
          <StyledTableCell
            key={headCell.id + index}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={
              rowList[index].long && rowList[index].checked
                ? { visiblity: "visible", minWidth: 175 }
                : rowList[index].checked
                ? { visiblity: "visible" }
                : { display: "none" }
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              disabled={!headCell.sortable}
              onClick={
                headCell.sortable
                  ? headCell.object
                    ? null
                    : createSortHandler(headCell.id)
                  : null
              }
              className={classes.root}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span
                  style={{ backgroundColor: "red", color: "red" }}
                  className={
                    headCell.object ? classes.none : classes.visuallyHidden
                  }
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        {area === "email" && (
          <StyledTableCell style={{ color: "#fff", fontWeight: "bold" }}>
            Resend
          </StyledTableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  selectAll: PropTypes.bool.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
  },

  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondaryFont.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  filterIcon: {
    color: "#fff",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <TableHead className={classes.tableHead}>
      {numSelected > 0 && (
        <TableRow
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
        >
          <TableCell align="center" colSpan={props.cols} style={{ padding: 0 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {numSelected > 0 ? (
                <Typography
                  className={classes.title}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {numSelected} selected
                </Typography>
              ) : (
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  User List
                </Typography>
              )}
            </div>
          </TableCell>
        </TableRow>
      )}
    </TableHead>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  addIcon: {
    color: "#fff",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableHead: {
    backgroundColor: theme.palette.secondary.main,
    fontWeight: "bold",
    color: "#fff",
  },
  root: {
    "&:hover": {
      color: theme.palette.primaryFont.main,
    },
    color: "#fff",
    fontWeight: "bold",
    backgroundColor: theme.palette.secondary.main,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  none: {
    display: "none",
  },
  rowListContainer: {
    padding: 0,
  },
  rowListCheck: {
    fontSize: 12,
    fontWeight: "bold",
  },

  actionsContainer: {
    display: "flex",
    backgroundColor: theme.palette.secondary.main,
    fontWeight: "bold",
    color: "#fff",
    borderRadius: "4px 4px 0px 0px",
    paddingBottom: 4,
    paddingTop: 4,
    alignItems: "center",
  },
  actionsItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  addItem: {
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  inputBaseAction: {
    backgroundColor: "#fff",
    paddingLeft: 4,
  },
  iconButtonAction: {
    paddingLeft: 8,
    paddingRight: 8,
    color: "#fff",
  },
  searchIcon: {
    color: "#fff",
  },
  highlightIcon: {
    marginRight: 0,
    color: "#fff",
  },
}));

export default function CustomTable(props) {
  const {
    cellList,
    data,
    headCells,
    handleSearch,
    handleClear,
    searchTerm,
    setSearchTerm,
    setUserEmail,
    setUsername,
    setUserMobile,
    selectNames,
    checked,
    area,
    names,
    handleToggle,
    actionsLists,
    actionsHandleChange,
    actionsNames,
    handleResend,
    setDateBefore,
    setDateAfter,
    dateAfter,
    dateBefore,
    page,
    setPage,
    productsAmount,
    handleMore,
    handleRequestSort,
    sortProperty,
    sortDirection,
    rowsPerPage,
    setRowsPerPage,
    getFullList,
    restricted,
  } = props;
  const classes = useStyles();

  const [selected, setSelected] = useState([]);
  const [rowList, setRowList] = useState([...headCells]);
  const [editItem, setEdit] = useState(false);

  const statusList = [
    { id: 1, value: "Active", name: "Active" },
    { id: 2, value: "Inactive", name: "Inactive" },
    { id: 3, value: "Deleted", name: "Deleted" },
    { id: 4, value: "Unavailable", name: "Unavailable" },
    { id: 5, value: "Pending", name: "Pending" },
    { id: 16, value: "Dispatched", name: "Dispatched" },
    { id: 9, value: "Completed", name: "Completed" },
    { id: 21, value: "Declined", name: "Declined" },
    { id: 23, value: "Refunded", name: "Refunded" },
    { id: 24, value: "Processing", name: "Processing" },
    { id: 27, value: "Approved", name: "Approved" },
  ];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const handleRowFilter = (event) => {
    setRowList(
      rowList.map((item) =>
        item.object
          ? item.objectProperty === [event.target.name][0]
            ? { ...item, checked: event.target.checked }
            : item
          : item.id === [event.target.name][0]
          ? { ...item, checked: event.target.checked }
          : item
      )
    );
  };

  const handleEdit = () => {};

  const handleEditClick = (event, id) => {
    props.handleEditClick(id);
    setEdit(true);
  };

  const handleDelete = () => {};

  return (
    <div className={classes.container}>
      <Container style={{ minWidth: 1280, maxWidth: 1600 }}>
        <Paper className={classes.paper}>
          <TableActions
            classes={classes}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            rowList={rowList}
            handleRowFilter={handleRowFilter}
            handleSearch={handleSearch}
            handleClear={handleClear}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setUserEmail={setUserEmail}
            setUsername={setUsername}
            setUserMobile={setUserMobile}
            editItem={editItem}
            setEdit={setEdit}
            checked={checked}
            names={names}
            handleToggle={handleToggle}
            actionsLists={actionsLists}
            actionsHandleChange={actionsHandleChange}
            actionsNames={actionsNames}
            area={area}
            selectNames={selectNames}
            selected={selected}
            data={data}
            setDateBefore={setDateBefore}
            setDateAfter={setDateAfter}
            dateAfter={dateAfter}
            dateBefore={dateBefore}
            productsAmount={productsAmount}
            getFullList={getFullList}
            restricted={restricted}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={sortDirection}
                orderBy={sortProperty}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                selectAll={true}
                headCells={headCells}
                rowList={rowList}
                area={area}
              />
              <EnhancedTableToolbar
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                numSelected={selected.length}
                selected={selected}
                cols={headCells.length + 1}
                area={area}
              />
              <TableBody>
                {stableSort(data, getComparator(sortDirection, sortProperty))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row.username}-${index}`}
                        selected={isItemSelected}
                      >
                        <StyledTableCell
                          padding="checkbox"
                          onClick={(event) => handleClick(event, row.id)}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </StyledTableCell>
                        {cellList.map((cell, index) => {
                          return (
                            <StyledTableCell
                              key={index}
                              align={
                                headCells[index].numeric ? "right" : "left"
                              }
                              onClick={
                                cell === "sku" || cell === "name"
                                  ? null
                                  : (event) => handleEditClick(event, row.id)
                              }
                              style={
                                (rowList[index].checked && cell === "sku") ||
                                cell === "name"
                                  ? { visiblity: "visible", cursor: "auto" }
                                  : rowList[index].checked
                                  ? { visiblity: "visible" }
                                  : { display: "none" }
                              }
                            >
                              {cell === "categories" ? (
                                row.categories.length &&
                                row.categories
                                  .map(function (obj) {
                                    return obj.name;
                                  })
                                  .join(", ")
                              ) : (cell === "updatedAt" ||
                                  cell === "claimStartDate" ||
                                  cell === "claimEndDate" ||
                                  cell === "redemptStartDate" ||
                                  cell === "redemptEndDate" ||
                                  cell === "startDate" ||
                                  cell === "endDate" ||
                                  cell === "lastLogin" ||
                                  cell === "createdAt") &&
                                row[cell] ? (
                                row[cell]
                                  .split("T")[0]
                                  .split("-")
                                  .reverse()
                                  .join("/")
                              ) : cell === "statusID" ? (
                                statusList.length &&
                                statusList.filter(
                                  (status) => status.id === row.statusID
                                )[0].name
                              ) : cell === "margin" ? (
                                (Number(row[cell]) * 100).toFixed(2)
                              ) : cell === "pointsMonetary" ? (
                                (Number(row[cell]) / 100).toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                  }
                                )
                              ) : headCells[index].numeric ? (
                                (Number(row[cell]) / 100).toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                  }
                                )
                              ) : cell === "images" &&
                                row[cell] &&
                                row[cell].length ? (
                                <img
                                  width={25}
                                  src={
                                    `${process.env.REACT_APP_PRODUCT_URL}/` +
                                    row[cell][0].objName
                                  }
                                  alt={row[cell][0].title}
                                />
                              ) : rowList[index].object ? (
                                row[cell][rowList[index].objectProperty]
                              ) : row[cell] ? (
                                row[cell]
                              ) : (
                                ""
                              )}
                            </StyledTableCell>
                          );
                        })}
                        {area === "email" && (
                          <StyledTableCell>
                            <button
                              onClick={(event) => handleResend(event, row.id)}
                            >
                              Resend
                            </button>
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    );
                  })}
                {emptyRows > 0 && data.length > 0 && (
                  <TableRow style={{ height: 33 }}>
                    <StyledTableCell colSpan={data.length} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={Number(productsAmount)}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleMore}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Container>
    </div>
  );
}
