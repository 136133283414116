import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomisation } from "../customisation/customisationSlice";
import {
  fetchPointsLeaderboardByClient,
  fetchPointsLeaderboardByProgram,
  fetchPointsLeaderboardByStore,
  selectLeaderboard,
} from "./leaderboardSlice";
import {
  fetchStores,
  getFullList as getFullStoresList,
  selectStores,
} from "../stores/storesSlice";
import { replaceUrlParam } from "../../app/utils";
import { Select, MenuItem, Card } from "@material-ui/core";
import {  
  selectUsers,  
} from "../users/usersSlice";

const useStyles = makeStyles((theme) => ({
  leaderboardContainer: {
    display: "flex",
    flexFlow: "row wrap",
    flexGrow: 1,
    paddingTop: 60,
    paddingBottom: 60,
    justifyContent: "space-around",
    backgroundColor: "#ddd",
    fontSize: 16,
  },
  leaderCard: {
    minWidth: 350,
    maxWidth: 450,
    marginBottom: 32,
    flexGrow: 1,
    marginLeft: 40,
    marginRight: 40,
  },
  cardTitle: {
    padding: 24,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontSize: 24,
    textAlign: "center",
    fontWeight: "bold",
  },

  memberContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    padding: 24,
    borderBottom: "1px solid #ddd",
    alignItems: "center",
    // justifyContent: 'center',
  },
  position: {
    width: 40,
    borderRadius: "50%",
    backgroundColor: "#ddd",
    height: 40,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
  },
  memberDetailsContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    marginLeft: 32,
  },
  memberName: {
    fontWeight: "bold",
  },
  memberPoints: {
    color: "#a29c9c",
    fontWeight: "bold",
  },
  branchSelect: {
    width: "80%", // Set the width to fill the container
    padding: "8px 12px", // Add some padding for better visual appearance
    marginTop: 12, // Add margin to separate from other elements
    borderRadius: 4, // Add some border radius for a softer look
    border: "1px solid #ddd", // Add a border for visual separation
    boxSizing: "border-box", // Include padding and border in the width
  },
}));

export default function Leaderboard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");

  const { clientLeaderboard, loading, error, programLeaderboard } =
    useSelector(selectLeaderboard);

  const { userAccount } =
    useSelector(selectUsers);
  const { storesList, storesAmount } = useSelector(selectStores);
  const { customisation } = useSelector(selectCustomisation);

  const [selectedBranch, setSelectedBranch] = useState("");

  console.log("selectedBranch", selectedBranch);

  let jwtToken = localStorage.getItem("clientToken");
  useEffect(() => {
    dispatch(
      fetchPointsLeaderboardByClient({
        token: jwtToken,
        clientID: Number(process.env.REACT_APP_CLIENT_ID),
      })
    );
    dispatch(
      fetchPointsLeaderboardByProgram({
        token: jwtToken,
        programID: 1,
      })
    );
  }, [dispatch]);

  const firstUpdateStore = useRef(true);

  useEffect(() => {
    if (!storesList.length) {
      dispatch(
        fetchStores({
          token: jwtToken,
          query: `?limit=100`,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      storesList.length &&
      storesList.length != storesAmount &&
      firstUpdateStore.current
    ) {
      let searchQuery = "?limit=100";
      searchQuery = replaceUrlParam(searchQuery, "limit", 100);
      dispatch(
        getFullStoresList({
          token: jwtToken,
          offset: storesList.length,
          query: searchQuery,
          targetLength: storesAmount,
        })
      );
      firstUpdateStore.current = false;
    }
  }, [storesList.length]);

  const formatPTS = (str) => {
    return str.toLocaleString();
  };

  const handleBranchChange = (event) => {
    setSelectedBranch(event.target.value);
  };

  useEffect(() => {
    if (selectedBranch.id) {
      dispatch(
        fetchPointsLeaderboardByStore({
          token: jwtToken,
          clientID: Number(process.env.REACT_APP_CLIENT_ID),
          storeID: selectedBranch.id,
        })
      );
    }
  }, [selectedBranch]);

  return (
    <div className={classes.leaderboardContainer}>
      <Card className={classes.leaderCard}>
        <div className={classes.cardTitle}>Leaderboard</div>
        {process.env.REACT_APP_ACD_BRANCH == "true" &&
          storesList.length > 0 && userAccount.roleID <= 2 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                padding: 20,
              }}
            >
              <label htmlFor="branch-select">Filter By Branch:</label>
              <Select
                value={selectedBranch}
                onChange={(e) => setSelectedBranch(e.target.value)}
                fullWidth
                variant="outlined"
                margin="dense"
                id="member-group"
                name="member-group"
              >
                {storesList.map((branch, i) => (
                  <MenuItem key={i} value={branch}>
                    {branch.name}
                  </MenuItem>
                ))}
              </Select>

              <hr />
            </div>
          )}
        {clientLeaderboard.map((member, index) => (
          <div key={member.userID} className={classes.memberContainer}>
            <div className={classes.position}>{index + 1}</div>
            <div className={classes.memberDetailsContainer}>
              <div className={classes.memberName}>{member.username}</div>
              <div className={classes.memberPoints}>
                {formatPTS(member.totalPointsEarned)} pts
              </div>
            </div>
          </div>
        ))}
      </Card>
    </div>
  );
}
