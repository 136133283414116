import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { replaceUrlParam } from "../../app/utils";
import CustomTable from "../../common/CustomTable";
import {
  fetchEmails,
  fetchMoreEmails,
  getFullList,
  resendEmail,
  selectEmails
} from "./emailsSlice";

import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexFlow: "column",
  },
}));

export default function EmailsList(props) {
  let jwtToken = localStorage.getItem("clientToken");
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const edit = props.location.pathname.includes("edit");
  const {
    emails,
    error,
    loading,
    emailsList,
    emailsFilter,
    emailsAmount,
    lastQuery,
  } = useSelector(selectEmails);

  const [statusNames, setStatusNames] = useState([]);
  const [statusIDs, setStatusIDs] = useState([]);
  const [statusList, setStatusList] = useState([
    { id: 14, value: "Failed", name: "Failed" },
    { id: 12, value: "Sent", name: "Sent" },
  ]);

  const [searchTerm, setSearchTerm] = useState("");
  const [dateBefore, setDateBefore] = useState("");
  const [dateAfter, setDateAfter] = useState("");

  const [tablePreferences, setTablePreferences] = useState(
    localStorage.getItem("EmailsAdminTable")
      ? JSON.parse(localStorage.getItem("EmailsAdminTable"))
      : {
          rowsPerPage: 25,
          currentPage: 0,
          sortProperty: "id",
          sortDirection: "asc",
        }
  );

  useEffect(() => {
    localStorage.setItem("EmailsAdminTable", JSON.stringify(tablePreferences));
  }, [tablePreferences]);

  const [numberShown, setNumberShown] = useState(25);

  const handleResend = (e, id) => {
    dispatch(
      resendEmail({
        token: jwtToken,
        id: id,
        query: "",
      })
    );
  };

  const headCells = [
    {
      id: "id",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Email #",
      long: false,
      sortable: true,
    },
    {
      id: "recipientName",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Recipient",
      long: false,
    },
    {
      id: "recipient",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Email",
      long: false,
    },
    {
      id: "tmplName",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Email Type",
      long: false,
    },
    {
      id: "createdAt",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Sent",
      long: false,
      sortable: true,
    },
    {
      id: "retriedAt",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Retried",
      long: false,
    },
    {
      id: "statusName",
      checked: true,
      numeric: false,
      disablePadding: false,
      label: "Status",
      long: false,
    },
  ];

  useEffect(() => {
    let searchQuery = lastQuery ? lastQuery : "";

    if (tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)) {
      searchQuery = replaceUrlParam(
        searchQuery,
        "limit",
        tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
      );
    }

    dispatch(
      fetchEmails({
        token: jwtToken,
        query: lastQuery
          ? searchQuery
          : `?limit=${tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)}`,
      })
    );

    setNumberShown(
      tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
    );
  }, [dispatch, tablePreferences.rowsPerPage]);

  const getFullListTrigger = () => {
    let searchQuery = lastQuery ? lastQuery : "";
    searchQuery = replaceUrlParam(searchQuery, "limit", 100);

    dispatch(
      getFullList({
        token: jwtToken,
        offset:
          tablePreferences.rowsPerPage > emailsList.length ? tablePreferences.rowsPerPage : emailsList.length,
        query: searchQuery,
        targetLength: emailsAmount,
      })
    );
  };

  const handleMore = (e, page) => {
    if (page < tablePreferences.currentPage) {
      setTablePreferences((oldTable) => ({ ...oldTable, currentPage: page }));
      setNumberShown((page + 1) * tablePreferences.rowsPerPage);
    } else {
      let expectedLen = (page + 1) * tablePreferences.rowsPerPage;
      if (emailsList.length < emailsAmount && emailsList.length < expectedLen) {
        dispatch(
          fetchMoreEmails({
            token: jwtToken,
            query: lastQuery,
            offset: numberShown,
          })
        );
      }
      setTablePreferences((oldTable) => ({
        ...oldTable,
        currentPage: oldTable.currentPage + 1,
      }));

      setNumberShown(
        (tablePreferences.currentPage + 2) * tablePreferences.rowsPerPage
      );
    }
  };

  const handleRequestSort = (event, property) => {
    let oldProperty = property;
    const isAsc =
      tablePreferences.sortProperty === property &&
      tablePreferences.sortDirection === "asc";
    setTablePreferences((oldTable) => ({
      ...oldTable,
      sortDirection: isAsc ? "desc" : "asc",
      sortProperty: oldProperty,
      currentPage: 0,
    }));
    switch (property) {
      case "updatedAt":
        property = "updated_at";
        break;
      case "createdAt":
        property = "created_at";
        break;
      case "statusID":
        property = "status_id";
        break;
    }

    let searchQuery = lastQuery ? lastQuery : "";

    if (property) {
      searchQuery = replaceUrlParam(searchQuery, "order_by", property);
    }
    searchQuery = replaceUrlParam(searchQuery, "sort", isAsc ? "desc" : "asc");
    searchQuery = replaceUrlParam(
      searchQuery,
      "limit",
      tablePreferences.rowsPerPage * (tablePreferences.currentPage + 1)
    );

    dispatch(
      fetchEmails({
        token: jwtToken,
        query: searchQuery,
      })
    );
  };

  const handleStatusChange = (event) => {
    let idsArr = [];
    event.target.value.forEach((selected) => {
      let selectedStatus = statusList.filter(
        (status) => status.name === selected
      );
      selectedStatus[0] && idsArr.push(selectedStatus[0].id);
    });

    setStatusNames(event.target.value);
    setStatusIDs(idsArr);
  };

  const [ready, setReady] = useState(false);
  if (loading === "loaded" && !ready) {
    setReady(true);
  }

  const handleEdit = (id) => {};

  const handleEditClick = (id) => {};

  const handleSearch = () => {
    let daterange = "";
    if (dateBefore && dateAfter) {
      daterange = `${dateAfter.toISOString().substring(0, 10)},${dateBefore
        .toISOString()
        .substring(0, 10)}`;
    } else if (dateBefore && !dateAfter) {
      daterange = `lte,${dateBefore.toISOString().substring(0, 10)}`;
    } else if (!dateBefore && dateAfter) {
      daterange = `gte,${dateAfter.toISOString().substring(0, 10)}`;
    }

    let searchQuery = lastQuery ? lastQuery : "";

    if (statusIDs.length) {
      searchQuery = replaceUrlParam(
        searchQuery,
        "statuses",
        statusIDs.join(",")
      );
    }
    if (daterange) {
      searchQuery = replaceUrlParam(searchQuery, "cdate", daterange);
    }
    //    if (searchTerm) {
    searchQuery = replaceUrlParam(searchQuery, "name", searchTerm);
    //    }

    dispatch(
      fetchEmails({
        query: searchQuery,
        token: jwtToken,
      })
    );
    setTablePreferences((oldTable) => ({
      ...oldTable,
      currentPage: 0,
    }));
  };

  const handleClear = () => {
    setStatusNames([]);
    setStatusIDs([]);
    setDateBefore("");
    setDateAfter("");
    setSearchTerm("");    

    dispatch(
      fetchEmails({
        token: jwtToken,
        query: `?limit=${tablePreferences.rowsPerPage}`,
      })
    );
    setTablePreferences((oldTable) => ({
      ...oldTable,
      currentPage: 0,
      sortDirection: "asc",
      sortProperty: "id",
    }));
  };

  const selectNames = ["status"];

  const cellList = headCells.map((cell) => {
    return cell.id;
  });

  let content;
  if (loading === "loading") {
    content = (
      <CircularProgress className={classes.progress} color="secondary" />
    );
  } else if (loading === "loaded" || loading === "idle") {
    content = (
      <CustomTable
        data={emailsList}
        actionsLists={[statusList]}
        actionsHandleChange={[handleStatusChange]}
        actionsNames={[statusNames]}
        headCells={headCells}
        cellList={cellList}
        handleEditClick={handleEditClick}
        handleSearch={handleSearch}
        handleClear={handleClear}
        handleEdit={handleEdit}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        area={"email"}
        selectNames={selectNames}
        handleResend={handleResend}
        setDateBefore={setDateBefore}
        setDateAfter={setDateAfter}
        dateAfter={dateAfter}
        dateBefore={dateBefore}
        productsAmount={emailsAmount}
        handleMore={handleMore}
        page={tablePreferences.currentPage}
        setPage={(page) =>
          setTablePreferences((oldTable) => ({
            ...oldTable,
            currentPage: page,
          }))
        }
        handleRequestSort={handleRequestSort}
        sortProperty={tablePreferences.sortProperty}
        sortDirection={tablePreferences.sortDirection}
        rowsPerPage={tablePreferences.rowsPerPage}
        setRowsPerPage={(rows) =>
          setTablePreferences((oldTable) => ({
            ...oldTable,
            rowsPerPage: rows,
          }))
        }
        getFullList={getFullListTrigger}
      />
    );
  }
  return (
    <Grid
      container
      key={emailsList.length}
      spacing={3}
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{ height: "100%" }}
    >
      {content}
    </Grid>
  );
}
