import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { setXClientHost } from "../../app/utils.js";
import { selectCustomisation } from "../customisation/customisationSlice";
import { fetchAuthToken, loggedIn, selectAuth } from "./authSlice.js";

const ColorButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primaryButton.main,
    color: theme.palette.primaryButtonFont.main,

    borderRadius: "24px",
    "&:hover": {
      backgroundColor: `${theme.palette.primaryButton.main}9b`,
    },
  },
}))(Button);

const SecondaryButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "transparent",
    border: "2px solid",
    borderColor: theme.palette.secondaryButtonFont.main,
    borderRadius: "24px",
    color: theme.palette.secondaryButtonFont.main,
    "&:hover": {
      backgroundColor: `${theme.palette.secondaryButtonFont.main}2b`,
    },
    fontWeight: "bold",
    minWidth: 175,
  },
}))(Button);

const PinkCheckbox = withStyles((theme) => ({
  root: {
    "&$checked": {
      // color: theme.palette.secondaryButton.main,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  container: { justifyContent: "center" },
  form: {
    borderBottom: "1px solid rgb(221, 221, 221)",
    marginTop: theme.spacing(1),
    width: "100%", // Fix IE 11 issue.
    color: theme.palette.secondaryFont.main,
  },
  paper: {
    color: theme.palette.secondaryFont.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 640,
  },
  remember: {
    display: "flex",
    justifyContent: "flex-start",
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
}));

export default function SignIn() {
  const { customisation } = useSelector(selectCustomisation);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:850px)");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { auth, error, loading, signedIn } = useSelector(selectAuth);
  let jwtToken = localStorage.getItem("clientToken");

  if (jwtToken) {
    dispatch(loggedIn());
    history.push("/");
  }

  const submitSignIn = (event) => {
    event.preventDefault();

    const credentials = {
      grant_type: "password",
      username: username,
      password: password,
      clientID: Number(process.env.REACT_APP_CLIENT_ID),
    };

    dispatch(
      fetchAuthToken({
        host: setXClientHost(),
        creds: credentials,
      })
    );
  };

  return (
    <Container className={classes.paper} component="main">
      <Grid container xs={12} className={classes.container}>
        {process.env.REACT_APP_CLIENT_ID == "12" ? (
          <div style={{textAlign: "center"}}>Welcome to <br/>{customisation.theme.clientName}</div>
        ) : (
          <div>Welcome to {customisation.theme.clientName}</div>
        )}
        <form className={classes.form} onSubmit={submitSignIn}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label={
              process.env.REACT_APP_TAYLORS_SIGN_IN == "true"
                ? "Email"
                : "Username/Email"
            }
            name="username"
            autoComplete="username"
            onChange={(e) => setUsername(e.target.value)}
            error={error}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
            error={error}
            helperText={error ? error.message : ""}
          />
          {/* <Grid className={classes.remember} item xs>
            <FormControlLabel
              control={<PinkCheckbox value="remember" />}
              label="Remember me"
            />
          </Grid> */}
          <ColorButton type="submit" fullWidth variant="contained">
            Sign In
          </ColorButton>
        </form>

        <Grid
          direction={matches ? "row" : "column"}
          container
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={5}>
            <Link to="/signin/forgot-password">
              <SecondaryButton type="submit" fullWidth variant="contained">
                Reset Password
              </SecondaryButton>
            </Link>
          </Grid>
          <Grid item xs={5}>
            <Link to="/signin/register">
              <SecondaryButton type="submit" fullWidth variant="contained">
                Register
              </SecondaryButton>
            </Link>
          </Grid>
        </Grid>
        {process.env.REACT_APP_AU_NZ_LINK && (
          <div>
            <div style={{ textAlign: "center", fontSize: 18, marginTop: 20 }}>
              {process.env.REACT_APP_AU_NZ_LINK == "AU" ? (
                <span>
                  New Zealand Member?
                  <br />
                  Sign in via{" "}
                  <a href="https://www.advantage-rewards.co.nz">
                    advantage-rewards.co.nz
                  </a>
                </span>
              ) : (
                <span>
                  Australian Member?
                  <br />
                  Sign in via{" "}
                  <a href="https://www.advantage-rewards.com.au">
                    advantage-rewards.com.au
                  </a>
                </span>
              )}
            </div>
          </div>
        )}
      </Grid>
    </Container>
  );
}
